<template>
    <a href="https://play.google.com/store/apps/details?id=net.offlinefirst.smoxy" target="_blank" itemprop="operatingSystem" aria-label="ANDROID" class="border-solid border border-black/25 rounded-lg hover:bg-black/10 hover:border-transparent hover:scale-[.98] transition-all">
        <svg width="163" height="48" viewBox="0 0 163 48" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M11 10.5201C11 8.62509 13.0261 7.41906 14.6919 8.32253L39.6188 21.8422C41.3375 22.7744 41.3691 25.2297 39.6752 26.2059L14.7482 40.5706C13.0816 41.531 11 40.3281 11 38.4045V10.5201ZM26.0858 24.5L13 37.5858V11.4142L26.0858 24.5ZM30.6718 29.086L15.7011 37.7132L27.5 25.9142L30.6718 29.086ZM38.6766 24.473L32.4661 28.0519L28.9142 24.5L32.9265 20.4877L38.6653 23.6003C39.009 23.7867 39.0154 24.2778 38.6766 24.473ZM15.3913 10.9771L31.0927 19.4931L27.5 23.0858L15.3913 10.9771Z" fill="currentColor"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M83.41 9.32279C81.9885 10.8213 81.9885 13.1643 83.41 14.6628C84.8764 16.1151 87.2453 16.1151 88.7118 14.6628C90.1393 13.1667 90.1393 10.8189 88.7118 9.32279C88.0105 8.61929 87.0563 8.22363 86.0609 8.22363C85.0655 8.22363 84.1113 8.61929 83.41 9.32279ZM88.0249 14.0628C86.9389 15.1399 85.1829 15.1399 84.0968 14.0628C83.0302 12.8918 83.0302 11.1058 84.0968 9.9348C85.1829 8.85772 86.9389 8.85772 88.0249 9.9348C89.0915 11.1058 89.0915 12.8918 88.0249 14.0628Z" fill="currentColor"/>
            <path d="M56.2507 14.6868C56.8692 14.0438 57.1958 13.1763 57.1544 12.2868C57.1545 12.1057 57.1384 11.925 57.1062 11.7468H53.5998V12.6108H56.2146C56.1967 13.155 55.9829 13.6746 55.6121 14.0748C54.7862 14.8699 53.5612 15.092 52.5069 14.6379C51.4526 14.1838 50.7762 13.1426 50.7923 11.9988C50.7661 11.2419 51.0507 10.5069 51.5804 9.96352C52.1102 9.4201 52.8394 9.11508 53.5998 9.11879C54.3564 9.07762 55.0884 9.39321 55.576 9.9708L56.2146 9.33479C55.9061 8.98146 55.5179 8.70594 55.0819 8.53079C54.6136 8.3312 54.1092 8.2291 53.5998 8.23079C52.6004 8.20861 51.6364 8.59959 50.9369 9.31079C49.8604 10.39 49.5383 12.0067 50.1196 13.4135C50.701 14.8203 52.0724 15.7429 53.5998 15.7548C54.5957 15.7901 55.5596 15.4018 56.2507 14.6868Z" fill="currentColor"/>
            <path d="M59.4197 9.28679H62.673V8.39879H58.4557V15.5988H62.673V14.7108H59.4197V12.4308H62.3839V11.5668H59.4197V9.28679Z" fill="currentColor"/>
            <path d="M66.6252 15.5988H65.6974V9.28679H63.6731V8.39879H68.6977V9.28679H66.6252V15.5988Z" fill="currentColor"/>
            <path d="M73.1681 8.39879H72.2402V15.5988H73.1681V8.39879Z" fill="currentColor"/>
            <path d="M77.2889 15.5988H76.3611V9.28679H74.3368V8.39879H79.3012V9.28679H77.2889V15.5988Z" fill="currentColor"/>
            <path d="M91.0855 8.39879H92.2181L95.7365 14.0028V8.39879H96.6644V15.5988H95.7004L92.0253 9.73079V15.5988H91.0855V8.39879Z" fill="currentColor"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M77.0239 31.2147C77.015 28.4012 79.2957 26.1121 82.1207 26.0988C83.489 26.0726 84.8093 26.6008 85.7792 27.5622C86.7492 28.5237 87.2857 29.8361 87.2658 31.1988C87.2658 34.0123 84.9779 36.2944 82.1528 36.2988C79.3277 36.3032 77.0327 34.0283 77.0239 31.2147ZM79.0988 32.5409C79.649 33.688 80.8474 34.3835 82.1207 34.2948C82.92 34.2764 83.6778 33.9372 84.2223 33.3542C84.7668 32.7713 85.0518 31.994 85.0126 31.1988C85.0095 29.9276 84.2264 28.7875 83.0379 28.3237C81.8494 27.86 80.4971 28.1669 79.6277 29.0977C78.7583 30.0284 78.5485 31.3939 79.0988 32.5409Z" fill="currentColor"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M70.9028 26.0988C68.0761 26.1054 65.7894 28.3917 65.7938 31.2068C65.7983 34.0219 68.0921 36.301 70.9188 36.2988C73.7455 36.2966 76.0358 34.0139 76.0358 31.1988C76.0557 29.8361 75.5192 28.5237 74.5492 27.5622C73.5793 26.6008 72.259 26.0726 70.8907 26.0988H70.9028ZM70.9028 34.2948C69.6288 34.3885 68.4269 33.6965 67.8728 32.5501C67.3187 31.4038 67.5251 30.0366 68.3933 29.1032C69.2614 28.1699 70.6145 27.8605 71.8045 28.3232C72.9945 28.786 73.7791 29.9266 73.7826 31.1988C73.8218 31.994 73.5368 32.7713 72.9923 33.3542C72.4478 33.9372 71.6899 34.2764 70.8907 34.2948H70.9028Z" fill="currentColor"/>
            <path d="M57.54 29.8308V27.6708L64.854 27.6108C64.938 28.0579 64.9784 28.512 64.9745 28.9668C65.0312 30.8337 64.3571 32.6495 63.0948 34.0308C61.6521 35.5352 59.6267 36.3447 57.54 36.2508C54.6228 36.3639 51.8773 34.8784 50.3849 32.3796C48.8925 29.8807 48.8925 26.7689 50.3849 24.2701C51.8773 21.7712 54.6228 20.2857 57.54 20.3988C59.5893 20.3811 61.5584 21.1906 62.9984 22.6428L61.4681 24.1668C60.4123 23.1517 58.9953 22.595 57.528 22.6188C54.3337 22.6188 51.7442 25.1977 51.7442 28.3788C51.7442 31.56 54.3337 34.1388 57.528 34.1388C59.03 34.1984 60.4873 33.6231 61.5404 32.5548C62.2391 31.8082 62.6637 30.8482 62.7453 29.8308H57.54Z" fill="currentColor"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M112.136 29.3508C111.492 27.4659 109.747 26.1721 107.75 26.0988C106.418 26.0962 105.144 26.6429 104.231 27.6091C103.318 28.5752 102.847 29.8746 102.93 31.1988C102.9 32.554 103.425 33.8629 104.384 34.8247C105.343 35.7866 106.654 36.3187 108.015 36.2988C109.727 36.308 111.329 35.4606 112.28 34.0428L110.533 32.8428C110.003 33.7235 109.046 34.2614 108.015 34.2588C106.947 34.2994 105.962 33.6855 105.533 32.7108L112.389 29.8908L112.136 29.3508ZM105.147 31.0548C105.098 30.305 105.354 29.567 105.857 29.0071C106.36 28.4473 107.068 28.1127 107.822 28.0788C108.617 28.0267 109.366 28.4516 109.726 29.1588L105.147 31.0548Z" fill="currentColor"/>
            <path d="M101.834 35.9988H99.5803V20.9988H101.834V35.9988Z" fill="currentColor"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M95.8932 27.2388H95.8088C95.122 26.474 94.1401 26.0375 93.1098 26.0388C90.3747 26.1709 88.2254 28.4178 88.2254 31.1448C88.2254 33.8718 90.3747 36.1187 93.1098 36.2508C94.1434 36.2681 95.1317 35.8287 95.8088 35.0508H95.8811V35.7828C95.8811 37.7388 94.8328 38.7828 93.1459 38.7828C91.9968 38.756 90.9779 38.0401 90.5674 36.9708L88.6033 37.7868C89.3596 39.619 91.1569 40.8107 93.1459 40.7988C95.7847 40.7988 97.9657 39.2508 97.9657 35.4828V26.3988H95.8932V27.2388ZM90.4678 31.1988C90.4678 32.8083 91.7016 34.1513 93.3109 34.2945C94.0909 34.2624 94.8242 33.9149 95.3413 33.3322C95.8602 32.7473 96.1163 31.9765 96.0498 31.1988C96.1093 30.4247 95.8525 29.6595 95.3375 29.0766C94.8226 28.4937 94.0932 28.1426 93.3146 28.1028C91.7035 28.2442 90.4678 29.5881 90.4678 31.1988Z" fill="currentColor"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M117.305 35.9988V20.9988H122.703C124.453 20.8736 126.127 21.7326 127.041 23.2248C127.955 24.7171 127.955 26.5925 127.041 28.0848C126.127 29.577 124.453 30.4361 122.703 30.3108H119.558V35.9988H117.305ZM119.546 28.1988H122.691L122.739 28.2348C124.167 28.2348 125.324 27.0824 125.324 25.6608C125.324 24.2392 124.167 23.0868 122.739 23.0868H119.546V28.1988Z" fill="currentColor"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M136.584 26.0388C134.908 25.939 133.331 26.8393 132.572 28.3308L134.572 29.1588C134.975 28.4192 135.778 27.9862 136.62 28.0548C137.198 27.9876 137.779 28.1544 138.232 28.5177C138.685 28.881 138.973 29.4103 139.03 29.9868V30.1428C138.306 29.7612 137.499 29.5634 136.68 29.5668C134.524 29.5668 132.343 30.7668 132.343 32.9388C132.386 33.8745 132.807 34.753 133.51 35.3746C134.214 35.9961 135.14 36.3079 136.078 36.2388C137.234 36.3215 138.343 35.7693 138.97 34.7988H139.042V35.9988H141.211V30.2268C141.211 27.5988 139.211 26.0748 136.644 26.0748L136.584 26.0388ZM136.307 34.2588C135.572 34.2588 134.548 33.8868 134.548 32.9868C134.548 31.7868 135.825 31.3908 136.957 31.3908C137.673 31.3732 138.381 31.5473 139.006 31.8948C138.85 33.2381 137.724 34.2619 136.367 34.2948L136.307 34.2588Z" fill="currentColor"/>
            <path d="M146.537 32.9028L149.115 26.3988H151.622L145.452 40.5468H143.103L145.392 35.4948L141.38 26.3988H143.789L146.464 32.9028H146.537Z" fill="currentColor"/>
            <path d="M128.86 35.9988H131.114V20.9988H128.86V35.9988Z" fill="currentColor"/>
        </svg>
    </a>
</template>